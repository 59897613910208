import {AxiosResponse} from "axios";

export function setupAxios(axios: any) {
    const baseUrl = process.env.REACT_APP_API_DOMAIN;

    axios.defaults.baseURL = baseUrl

    axios.interceptors.response.use((response: AxiosResponse) => {
        response.data = keysToCamelCase(response.data);

        return response;
    });
}

function toCamelCase(str: string): string {
    // Replace underscores with spaces, capitalize the first letter of each word,
    // remove spaces, and make the first character lowercase.
    return str
        .replace(/([-_][a-z])/gi, ($1) => {
            return $1
                .toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
}

function keysToCamelCase(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map((v) => keysToCamelCase(v));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [toCamelCase(key)]: keysToCamelCase(obj[key]),
            }),
            {},
        );
    }
    return obj;
}