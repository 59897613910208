import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState} from 'react'
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import {toast, Toaster} from 'react-hot-toast'
import axios, {AxiosResponse} from 'axios'
import {useNavigate} from 'react-router-dom'
import {useUser} from '../auth/AuthWrapper'
import {ChangePasswordModal} from './components/ChangePasswordModal'
import {redirectToAuth} from 'supertokens-auth-react'
import {Helmet} from "react-helmet-async";
import TenantService from "../tenant/services/TenantService";

const SettingsPageContent = () => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false)

  const navigate = useNavigate()

  const {user} = useUser()

  const exportSales = async () => {
    toast.loading('Please wait...', {id: 'exportSales'})

    try {
      const response = await axios.post('/api/v1/export/order')

      toast.success(response.data.message, {
        id: 'exportSales',
        duration: 8000,
      })
    } catch (e: any) {
      toast.error(e.response.data.details.message, {
        id: 'exportSales',
        duration: 5000,
      })
    }
  }

  const onPasswordChange = async () => {
    await redirectToAuth()
  }

  const exportItems = async () => {
    toast.loading('Please wait...', {id: 'exportItems'})

    try {
      const response = await axios.post('/api/v1/export/item')

      toast.success(response.data.message, {
        id: 'exportItems',
        duration: 8000,
      })
    } catch (e: any) {
      toast.error(e.response.data.details.message, {
        id: 'exportItems',
        duration: 5000,
      })
    }
  }

  const onboard = async () => {
    await visitUrl('/api/v1/stripe/onboard')
  }

  const gotoDashboard = async () => {
    await visitUrl('/api/v1/stripe/dashboard')
  }

  const visitUrl = async (endpoint: string) => {
    toast.loading('Please wait...', {id: 'onboard'})

    try {
      const response = await axios.post(endpoint)

      window.location.href = response.data.url

      toast.success('Redirecting to Stripe...', {
        id: 'onboard',
        duration: 8000,
      })
    } catch (e: any) {
      toast.error('Oops, something went wrong...', {
        id: 'onboard',
        duration: 5000,
      })
    }
  }

  return (
    <>
      <Container>
        <Row className='g-6' style={{'--bs-gutter-x': '2.5rem'} as any}>
          <Col xs={12} md={6}>
            <Card className='h-100'>
              <Card.Header>
                <Card.Title>Resource Export</Card.Title>
              </Card.Header>

              <Card.Body>
                <p className='text-grey'>Easily export all of your item or sale data history</p>

                <p className='text-muted'>
                  Upon a resource export, you'll receive an email with a CSV.
                </p>

                <div className='mt-20 d-flex flex-row justify-content-end'>
                  <Button variant='primary' className='me-5' onClick={() => exportItems()}>
                    {/*<i className='fa fa-square text-secondary px-5'></i>*/}
                    Export Items
                  </Button>

                  <Button variant='primary' onClick={() => exportSales()}>
                    {/*<i className='fa fa-square text-secondary px-5'></i>*/}
                    Export Sales
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6}>
            <Card className='h-100'>
              <Card.Header>
                <Card.Title>Account Security</Card.Title>
              </Card.Header>

              <Card.Body>
                <div className='d-flex flex-row justify-content-between'>
                  <div>
                    <span className='text-grey d-block'>Password</span>
                    <span className='text-muted'>●●●●●●●●●●●●●●●●</span>
                  </div>

                  <Button variant={'danger'} onClick={() => setShowChangePasswordModal(true)}>
                    {/*<i className='fa fa-square text-secondary px-5'></i>*/}
                    Change Password
                  </Button>
                </div>

                {/*<div*/}
                {/*    className='d-flex bg-light-primary rounded border-primary border border-dashed p-6 mt-15'>*/}
                {/*    <div className='d-flex flex-row justify-content-between w-100'>*/}
                {/*        <div className='d-flex flex-row align-items-center'>*/}
                {/*            /!*<i className='fa fa-square text-secondary fs-1'></i>*!/*/}

                {/*            <h3 className='fs-4 m-0 ms-5'>Secure your account</h3>*/}
                {/*        </div>*/}

                {/*        <Button variant='primary'>*/}
                {/*            /!*<i className='fa fa-square text-secondary px-5'></i>*!/*/}
                {/*            Setup 2FA*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</div>*/}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <ChangePasswordModal
        visible={showChangePasswordModal}
        onConfirm={onPasswordChange}
        onCancel={() => setShowChangePasswordModal(false)}
      />
    </>
  )
}

const SettingsPageWrapper = () => {
  const intl = useIntl()
  const tenantData = TenantService.getTenantFromLocalStorage();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SETTINGS'})}</PageTitle>
      <SettingsPageContent />
      <Helmet>
        <title>
          {intl.formatMessage({id: 'MENU.SETTINGS'})}
          {tenantData?.title ? ` - ${tenantData.title}` : ''}
        </title>
      </Helmet>
    </>
  )
}

export const SettingsPage = SettingsPageWrapper
