import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {Button, Dropdown, Form} from "react-bootstrap";
import InventoryService, {InventoryItemStatus, Location} from "../services/inventory.service";

interface Props {
    onFilterChange: (filters: Record<string, string>) => void,
    displayStatus: boolean
}


const statusFilters = {
    'All': InventoryItemStatus.All,
    'Awaiting Item': InventoryItemStatus.Awaiting,
    'Active': InventoryItemStatus.Active,
    'Active - Queue': InventoryItemStatus.InQueue,
    'Withdrawal Requested': InventoryItemStatus.WithdrawalRequested,
    'Withdrawn': InventoryItemStatus.Withdrawn,
    'Sold': InventoryItemStatus.Sold,
}


const InventoryFilterDropdown: React.FC<Props> = ({onFilterChange, displayStatus = false}) => {
    const [isOpen, setIsOpen] = useState(false);

    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<string | undefined>(undefined);

    const [selectedSize, setSelectedSize] = useState<string | undefined>(undefined);
    const [selectedStatus, setSelectedStatus] = useState<InventoryItemStatus | undefined>(InventoryItemStatus.All);

    const loadData = async () => {
        setLocations(await InventoryService.fetchLocations())
    }

    const handleLocationChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedLocationId(event.target.value)
    }

    const handleSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedSize(event.target.value)
    }

    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedStatus(event.target.value as InventoryItemStatus)
    }

    const applyFilters = (close = true) => {
        onFilterChange({
            ...selectedLocationId && {'location_id': selectedLocationId},
            ...selectedSize && {'size': selectedSize},
            ...selectedStatus && {'status': selectedStatus}
        })

        if (close)
            handleClose();
    }

    const resetFilters = () => {
        setSelectedLocationId('')
        setSelectedSize('')
        setSelectedStatus(InventoryItemStatus.All)
    }

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            // Check if the click was outside the Dropdown
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const handleToggleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Toggle the dropdown's open/closed state
        setIsOpen(prevOpen => !prevOpen);
    };

    useEffect(() => {
        (async () => {
            await loadData();
        })()
    }, [])

    return (
        <>
            <Dropdown show={isOpen} onToggle={handleOpen} ref={dropdownRef}>
                <Dropdown.Toggle variant="light" id="dropdown-basic" onClick={handleToggleClick}>
                    <i className="ki-duotone ki-filter fs-2"><span className="path1"></span><span
                        className="path2"></span></i>
                    Filter
                </Dropdown.Toggle>

                <Dropdown.Menu className={'w-400px px-2 py-4'}>
                    <Dropdown.Header>
                        <span className='text-white fw-bold h4'>Filter Options</span>
                    </Dropdown.Header>

                    <Dropdown.Divider/>

                    {displayStatus && (
                        <Form.Group className="mb-3 p-4" controlId="ownsership">
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                className='form-select form-select-solid'>
                                {Object.entries(statusFilters).map(([key, value]) => {
                                    return (
                                        <option value={value}>{key}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>)
                    }

                    <Form.Group className="mb-3 p-5" controlId="location">
                        <Form.Label>Location</Form.Label>
                        <Form.Select
                            value={selectedLocationId}
                            onChange={handleLocationChange}
                            className='form-select form-select-solid'>
                            <option value=''>Select location...</option>
                            {locations.map(l => {
                                return (
                                    <option value={l.id}>{l.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3 p-4" controlId="size">
                        <Form.Label>Size</Form.Label>
                        <Form.Control
                            value={selectedSize}
                            onChange={handleSizeChange}
                            required={true}
                            className='form-control-solid min-w-125px'
                            type="email"
                            placeholder="Type here"
                        />
                    </Form.Group>

                    <div className='d-flex justify-content-end pe-4'>
                        <Button variant={'secondary'} className='me-3' onClick={resetFilters}>Reset</Button>
                        <Button variant={'primary'} onClick={() => applyFilters(true)}>Apply</Button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default InventoryFilterDropdown;