import React, {useState} from "react";

import {InventoryItem, InventoryItemStatus} from "../services/inventory.service";
import InventoryFilterDropdown from "./InventoryFilterDropdown";
import {PaginatedTable, TableHeader} from "components";
import TableUtilities from "components/dist/tables/table-utilities";
import StatusUtilities from "../../components/status-utilities";


interface Props {
    statusFilter?: InventoryItemStatus,
    selectable?: boolean
    onSelected?: (selectedItems: InventoryItem[]) => void
    actions?: JSX.Element,
}


const InventoryTable: React.FC<Props> = ({statusFilter, onSelected, selectable = false, actions}) => {
    const [status, setStatus] = useState<InventoryItemStatus>(InventoryItemStatus.All);
    const [selectedItems, setSelectedItems] = useState<InventoryItem[]>([]);

    const [tableFilters, setTableFilters] = useState<Record<string, string>>({
        "status": status,
    });

    const onFilterChange = (filters: Record<string, string>) => {
        setTableFilters({
            ...filters
        });
    }

    const statusBadge = (status: string) => StatusUtilities.statusBadge(status);

    const image = (row: InventoryItem) => {
        return TableUtilities.tableImage(row.product.imageUrl, row.id)
    }

    const titleAndSize = (row: InventoryItem) => {
        return <div>
            <a className={'text-white d-block'} href={`/inventory/items/${row.id}`}>{row.product.title}</a>
            <span className={'mt-3'}>{row.size} / {row.shape}</span>
        </div>
    }

    const formatCondition = (condition: string) => {
        return (
            <>
                <span className='text-capitalize'>
                    {condition}
                </span>
            </>
        )
    }

    const formatCurrency = (price: number) => {
        return TableUtilities.formatCurrencyCents(price);
    }

    function formatDate(date: string) {
        return TableUtilities.formatDate(date)
    }

    const headers: TableHeader[] = [
        {key: 'image', label: '', format: (cell) => image(cell.row)},
        {key: 'product.title', label: 'Item Title', format: (cell) => titleAndSize(cell.row)},
        {key: 'itemRef', label: 'Reference'},
        {key: 'price', label: 'Price', format: (cell) => formatCurrency(cell.item)},
        {
            key: 'payoutAmount',
            label: 'Payout Amount',
            format: (cell) => TableUtilities.formatCurrencyCents(cell.item)
        },
        {key: 'location.name', label: 'Location'},
        {key: 'createdAt', label: 'Created At', format: (cell) => formatDate(cell.item)},
        {key: 'status', label: 'Status', format: (cell) => statusBadge(cell.item)}
    ]

    const onItemsSelected = (items: any[]) => {
        if (!!onSelected) {
            onSelected(items as InventoryItem[])
        }

        setSelectedItems(items);
    }

    const tableActions = () => (
        <>
            <div className='d-flex'>
                <InventoryFilterDropdown displayStatus={!statusFilter} onFilterChange={onFilterChange}/>
                {!!actions && selectable && selectedItems.length > 0 && actions}
            </div>
        </>
    )

    return (
        <>
            <PaginatedTable className={''} headers={headers} method={'post'} dataUrl={'/api/v1/item/consignor/search'}
                            actions={tableActions()} filters={tableFilters} selectable={selectable}
                            onSelected={onItemsSelected} paginate={true} />
        </>
    )
}

export default InventoryTable;