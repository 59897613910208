import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PayoutService, { PayoutBatchRoot } from "../dashboard/payouts/payout.service";
import { Card, Col, Row } from "react-bootstrap";
import TableUtilities from "components/dist/tables/table-utilities";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";


export const ViewPaidPayout = () => {
    const [payoutBatchRoot, setPayoutBatchRoot] = useState<PayoutBatchRoot | null>(null)
    const {id} = useParams();

    useEffect(() => {
        (async () => {
            const response = await PayoutService.fetchBatchPayout(id!)
            setPayoutBatchRoot(response)
        })()
    }, [])

    return (
        <Row className="g-6">
            <Col md={8}>
                <Card className="pt-4" title="">
                    <Card.Body>
                        <div>
                            <h2 className="d-inline-block align-middle">
                                Payout ID: {payoutBatchRoot?.batchPayout?.payoutRef}
                            </h2>
                            <p className='small text-muted fw-bolder'>
                                {payoutBatchRoot?.batchPayout && TableUtilities.formatDateLong(payoutBatchRoot.batchPayout.createdAt)}
                            </p>
                        </div>
                        <div className="mt-4">
                            {payoutBatchRoot?.batchPayout?.payout?.map(payout => (
                                <div className="d-flex mt-7">
                                    <div className="me-5 bg-white p-2 rounded">
                                        <Link to={`/inventory/items/${payout.orderItem.item.id}`}>
                                            <div
                                                style={{
                                                    backgroundImage: `url("${payout.orderItem.item.product.imageUrl}")`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center center',
                                                    height: '90px',
                                                    width: '90px'
                                                }}>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="d-flex justify-content-center flex-column gap-1 w-100">
                                        <span className='h1 text-dark fw-bolder mb-1 fs-5'>
                                            <Link className='text-white' to={`/inventory/items/${payout.orderItem.item.id}`}>
                                                {payout.orderItem.item.product.title}
                                            </Link>
                                            <span className="float-end">
                                                {TableUtilities.formatCurrencyCents(payout.orderItem.payoutAmount)}
                                            </span>
                                        </span>

                                        <span className="text-muted text-capitalize">
                                            {payout.orderItem.item.size} - {payout.orderItem.item.shape}
                                            <span className="text-muted text-capitalize float-end">
                                                Sold Price: {TableUtilities.formatCurrencyCents(payout.orderItem.subtotal)}
                                            </span>
                                        </span>
                                        <span className="text-muted">{payout.orderItem.item.product.sku}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card className="pt-4" title="">
                    <Card.Body>
                        <div className="pb-7">
                            <h2>Payout Details</h2>
                        </div>

                        <div className="d-flex flex-column gap-1">
                            <div className="d-flex justify-content-between w-100 fs-6">
                                <p className="text-muted">Check Count</p>
                                <p className="text-white">
                                    {payoutBatchRoot?.details?.checkCount}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between w-100 fs-6">
                                <p className="text-muted">Item Count</p>
                                <p className="text-white">
                                    {payoutBatchRoot?.details?.payoutCount}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between w-100 fs-6">
                                <p className="text-muted">Payout Amount</p>
                                <p className="text-white">
                                    {payoutBatchRoot?.details?.payoutAmountSubtotal ? TableUtilities.formatCurrencyCents(payoutBatchRoot.details.payoutAmountSubtotal) : 0}
                                </p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}


const ViewPaidPayoutPage = () => {
    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[{
                title: intl.formatMessage({id: "MENU.PAYOUT_PAID"}),
                path: "/payouts/paid",
                isActive: false
            }]}>{intl.formatMessage({id: "MENU.PAYOUT_PAID"})}</PageTitle>
            <ViewPaidPayout/>
        </>
    )
}


export default ViewPaidPayoutPage;