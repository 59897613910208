export default {
    statusBadge(status: string) {
        const statusLower = status.toLowerCase();
        const displayValue = status.replace('_', ' ')

        const statusMap = {
            'active': 'badge-light-success',
            'paid': 'badge-light-success',
            'sold': 'badge-light-success',
            'fulfilled': 'badge-light-success',
            'in-queue': 'badge-light-warning',
            'awaiting_item': 'badge-light-warning',
            'partially_fulfilled': 'badge-light-warning',
            'store_owned': 'badge-secondary',
            'awaiting_payment': 'badge-light-info',

        } as Record<string, string>

        const badgeClass = `${statusMap[statusLower] ?? 'badge-light-danger'}`;

        return (
            status === 'in_queue' ? (
                <span className={`badge badge-light-success text-capitalize min-w-75px justify-content-center`}>
                    Active - Queue
                </span>
            ) : (
                <span
                    className={`badge ${badgeClass} text-capitalize min-w-75px justify-content-center`}>
                    {displayValue}
                </span>
            )
        )
    },
}