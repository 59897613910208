import {Button, Form, Modal} from "react-bootstrap";
import React, {ChangeEvent, useState} from "react";
import axios from "axios";
import toast from "react-hot-toast";

interface Props {
    visible: boolean,
    onConfirm: () => void,
    onCancel: () => void
}

interface Errors {
    currentPassword?: string
    newPassword?: string
}

export const ChangePasswordModal = ({visible, onConfirm, onCancel}: Props) => {

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');

    const [errors, setErrors] = useState<Errors>({});

    const changePassword = async (e: React.FormEvent) => {
        e.preventDefault();

        toast.loading('Please wait...', {id: 'changePassword'})

        try {
            const response = await axios.post('/api/v1/auth/change_password', {
                old_password: currentPassword,
                new_password: newPassword
            })

            setTimeout(() => {
                onConfirm()
            }, 2000)

            toast.success(response.data.message, {
                id: 'changePassword',
                duration: 8000
            })
        } catch(e: any) {
            if(e.response.data.detail) {
                const errors = {} as Errors

                e.response.data.detail.forEach((err: any) => {
                    if(err.loc[1].includes('old')) {
                        errors.currentPassword = err.msg;
                    }

                    if(err.loc[1].includes('new')) {
                        errors.newPassword = err.msg;
                    }
                })

                setErrors(errors)
            }

            toast.error(e.response.data.details.message, {
                id: 'changePassword',
                duration: 5000
            })
        }
    }

    const handleCurrentPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setErrors({
            ...errors,
            currentPassword: undefined
        })

        setCurrentPassword(event.target.value)
    }

    const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setErrors({
            ...errors,
            newPassword: undefined
        })

        setNewPassword(event.target.value)
    }

    return (
        <>
            <Modal show={visible} onHide={onCancel} centered>
                <Modal.Header closeButton className='border-0 pb-0'>
                </Modal.Header>
                <Modal.Body>
                    <div className='px-15'>
                        <h2 className={'text-center'}>Change Password</h2>

                        <Form className='mt-15 mb-10' onSubmit={changePassword}>
                            <Form.Group className="mb-3 position-relative" controlId="currentPassword">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    className={'form-control-solid'}
                                    placeholder="Current Password"
                                    onChange={handleCurrentPasswordChange}
                                    isInvalid={!!errors.currentPassword}
                                    required
                                />
                                <p className='text-danger fw-bold mt-3'>
                                    {errors.currentPassword}
                                </p>
                            </Form.Group>

                            <Form.Group className="mb-3 position-relative" controlId="newPassword">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    className={'form-control-solid'}
                                    placeholder="New Password"
                                    onChange={handleNewPasswordChange}
                                    isInvalid={!!errors.newPassword}
                                    required
                                />
                                <p className='text-danger fw-bold mt-3'>
                                    {errors.newPassword}
                                </p>
                            </Form.Group>

                            <div className='text-center mt-10'>
                                <Button type={'submit'} variant={'primary'} className='w-100'>Confirm</Button>
                            </div>
                            <div className='text-center mt-5 mb-5'>
                                <Button variant={'secondary'} className='w-100' onClick={onCancel}>Cancel</Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
