import {useIntl} from "react-intl";
import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from "react";
import {Button, Card, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {CatalogItemView, CatalogService, ItemCard, SizeSelect} from "components";
import axios from "axios";
import {toast, Toaster} from "react-hot-toast";
import {Link, useNavigate, useParams} from "react-router-dom";
import { useUser } from "../../auth/AuthWrapper";
import InventoryService, {Location, VariantAvailability} from "../../inventory/services/inventory.service";
import {PageTitle} from "../../../../_metronic/layout/core";
import {Helmet} from "react-helmet-async";
import TenantService from "../../tenant/services/TenantService";

interface Variant {
    size: string,
    shape: string,
    locationId: string,
    paidPrice: string
    listPrice: string
}

const OnboardPage = () => {
    const [item, setItem] = useState<CatalogItemView | undefined>(undefined);
    const [selectedSizes, setSelectedSizes] = useState<Variant[]>([])
    const [variantSizes, setVariantSizes] = useState<VariantAvailability[]>([])
    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<string>('');
    const [tableError, setTableError] = useState<string>('');
    const [formErrors, setFormErrors] = useState({
        location: false
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate()
    const {id} = useParams()
    const locationRef = useRef<HTMLDivElement>(null);
    const { user } = useUser()

    const loadData = async () => {
        setLocations(await InventoryService.fetchLocations())
        setItem(await CatalogService.getItem(id!))
    }

    const addSize = (size: string) => {
        setSelectedSizes((prev: Variant[]) => [
            ...prev,
            {
                size,
                listPrice: '',
                paidPrice: '',
                locationId: '',
                shape: 'New'
            }
        ])
    }

    const handleAddCustomSize = () => {
        addSize('')
    }

    const isNumber = (str: string) => {
        // This regular expression allows numbers and decimal numbers
        const regex = /^[0-9]*(\.[0-9]*)?$/;

        return regex.test(str)
    }

    const updateSizeAtIndex = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const newSizes = [...selectedSizes]

        newSizes[index].size = event.target.value

        setSelectedSizes(newSizes)
    }

    const handleListPriceChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        
        // Only allow digits (whole numbers)
        if (/^\d*$/.test(inputValue)) {
            const newSizes = [...selectedSizes];
            
            // Convert to number and format as integer
            newSizes[index].listPrice = inputValue === '' ? '0' : parseInt(inputValue, 10).toString();
            
            setSelectedSizes(newSizes);
        }
    }

    const handlePaidPriceChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        if (isNumber(event.target.value)) {
            const newSizes = [...selectedSizes]

            newSizes[index].paidPrice = Number(event.target.value).toFixed(0)

            setSelectedSizes(newSizes)
        }
    }

    const handleShapeChange = (index: number, event: ChangeEvent<HTMLSelectElement>) => {
        const newSizes = [...selectedSizes]

        newSizes[index].shape = event.target.value

        setSelectedSizes(newSizes)
    }

    const handleRemoveVariant = (index: number) => {
        const newSizes = selectedSizes.filter((item, itemIndex) => itemIndex !== index)

        setSelectedSizes(newSizes)
    }

    const calculateConsignorPayoutAmount = (price: number): string => {
        if (price < 0) return "0.00";

        const percentFee = user?.tier.percent;
        const minimumFee = user?.tier.minimumFee;
    
        if (percentFee === undefined || minimumFee === undefined) {
            return "0.00";
        }
    
        const normalizedPercentFee = percentFee / 100;
        const normalizedMinimumFee = minimumFee / 100;
    
        let fee: number;
        if (price * normalizedPercentFee < normalizedMinimumFee) {
            fee = price - (minimumFee / 100);
        } else {
            fee = price - (price * normalizedPercentFee);
        }

        if (fee < 0) {
            return "0.00";
        }
    
        return fee.toFixed(2);
    }

    const handleCreateItems = async (event: FormEvent) => {
        event.preventDefault();

        if (isLoading) return;

        setTableError('')
        setFormErrors({ location: false }); // Reset form errors

        // Validate location
        if (!selectedLocationId) {
            setFormErrors(prev => ({ ...prev, location: true }));
            return;
        }

        const itemsToCreate = selectedSizes.filter(s => !!s.size && s.size.length > 0);

        if (itemsToCreate.length === 0) {
            setTableError('Please add some items to create.')

            return;
        }

        setIsLoading(true)
        toast.loading('Please wait...', {id: 'onboard'})

        try {
            const response = await axios.post('/api/v1/item/create', {
                "product_id": id,
                "sizes": itemsToCreate.map(sz => ({
                    "location_id": selectedLocationId,
                    "size": sz.size,
                    "price": sz.listPrice,
                    "shape": sz.shape
                }))
            })

            toast.success(response.data.message, {
                id: 'onboard',
                duration: 8000
            })

            navigate(`/catalog/${id}`)
        } catch (e: any) {
            if (e.response.data.details) {
                toast.error(e.response.data.details.message, {
                    id: 'onboard',
                    duration: 5000
                })
            } else {
                toast.error('Something went wrong...', {
                    id: 'onboard',
                    duration: 5000
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!item) return;

        (async () => {
            const variantAvailability = await InventoryService.fetchItemAvailability(id!);

            setVariantSizes(item.variant.map(v => ({
                size: v.size,
                price: variantAvailability.find(va => va.size === v.size)?.price
            })))
        })()
    }, [item])

    useEffect(() => {
        (async () => {
            await loadData();
        })()
    }, [])

    useEffect(() => {
        if (formErrors.location) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      }, [formErrors.location]);

    return (
        <>
            <Row>
                <Col xs={12} md={4} className='pe-3 pe-sm-5 mb-5 mb-md-0'>
                    <ItemCard
                        imageUrl={item?.imageUrl ?? ''}
                        subtitle={item?.colorway ?? ''}
                        productCode={item?.sku ?? ''}
                        title={item?.primaryTitle ?? item?.title ?? ''}
                        hideFooter={true}
                    />
                </Col>

                <Col xs={12} md={8}>
                    <Card className='p-12'>
                        <div ref={locationRef}>
                            <Row className='mb-10'>
                                <Form.Group as={Col} controlId='exampleForm.location'>
                                    <Form.Label>Location</Form.Label>
                                    <Form.Select
                                        className={`form-select form-select-solid ${formErrors.location ? 'is-invalid' : ''}`}
                                        placeholder=''
                                        value={selectedLocationId}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            setSelectedLocationId(e.target.value);
                                            setFormErrors(prev => ({ ...prev, location: false }));
                                        }}
                                        required={true}
                                    >
                                        <option value=''>Select location...</option>
                                        {locations.map(l => (
                                            <option value={l.id}>{l.name}</option>
                                        ))}
                                    </Form.Select>
                                    {formErrors.location && (
                                        <Form.Control.Feedback type="invalid">
                                            Please select a location.
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Row>
                            <label className='form-label'>Select Sizes</label>
                            <div>
                                <SizeSelect sizes={variantSizes} allowCustom={false} onSizeSelected={addSize}/>
                            </div>
                        </div>

                        <form onSubmit={handleCreateItems}>
                            <div className='tableContainer mt-20'>
                                <Row
                                    className='m-0 text-start text-muted fw-bolder fs-7 text-uppercase gs-0 flex-nowrap item-headers'>
                                    <Col className="flex-grow-1" style={{minWidth: '90px'}}>Size</Col>
                                    <Col className="flex-grow-1" style={{minWidth: '100px'}}>Condition</Col>
                                    <Col className="flex-grow-1" style={{minWidth: '100px'}}>List Price</Col>
                                    <Col className="flex-grow-1" style={{minWidth: '100px'}}>Payout Price</Col>
                                    <Col xs={"auto"}></Col>
                                </Row>
                                <div>
                                    {selectedSizes.map((s, index) => (
                                        <Row className="m-0 mt-5 flex-nowrap item">
                                            <Col className="flex-grow-1" style={{minWidth: '90px'}}>
                                                <InputGroup className="w-90 size-input">
                                                    <InputGroup.Text id="basic-addon1"
                                                                     className='border-0 pe-0 py-1'></InputGroup.Text>
                                                    <Form.Control
                                                        className={'form-control-solid py-1 ps-0 pe-1'}
                                                        placeholder=""
                                                        aria-label=""
                                                        value={s.size}
                                                        required={true}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateSizeAtIndex(index, e)}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col className="flex-grow-1" style={{minWidth: '100px'}}>
                                                <Form.Select aria-label="Default select example"
                                                             className='form-select form-select-solid p-1 ps-4 pe-10'
                                                             placeholder=""
                                                             value={s.shape}
                                                             required={true}
                                                             onChange={(e: ChangeEvent<HTMLSelectElement>) => handleShapeChange(index, e)}
                                                >
                                                    <option value="New">New</option>
                                                    <option value="Pre-Owned">Pre-Owned</option>
                                                    <option value="New (Rep Box)">New (Rep Box)</option>
                                                    <option value="Pre-Owned (Rep Box)">Pre-Owned (Rep Box)</option>
                                                </Form.Select>
                                            </Col>
                                            <Col className="flex-grow-1" style={{minWidth: '100px'}}>
                                                <InputGroup className="size-input">
                                                    <InputGroup.Text id="basic-addon1"
                                                                     className='border-0 pe-0 py-1'>$</InputGroup.Text>
                                                    <Form.Control
                                                        required={true}
                                                        className={'form-control-solid py-1 ps-0 pe-1'}
                                                        placeholder="0"
                                                        aria-label=""
                                                        value={s.listPrice}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleListPriceChange(index, e)}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col className="flex-grow-1" style={{minWidth: '100px'}}>
                                                <InputGroup className="size-input">
                                                    <InputGroup.Text id="basic-addon1"
                                                                        className='border-0 pe-0 py-1'>$</InputGroup.Text>
                                                    <Form.Control
                                                        readOnly
                                                        className={'form-control-solid py-1 ps-0 pe-1'}
                                                        value={calculateConsignorPayoutAmount(Number(s.listPrice))}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col xs={"auto"}>
                                                <Button className={'p-0'} variant='link'
                                                        onClick={() => handleRemoveVariant(index)}>
                                                    <i className="fa-solid fa-x text-danger text-danger-hover"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                    <p className='text-danger text-center'>{tableError}</p>
                                </div>
                            </div>

                            <div className='text-end mt-5'>
                                <Link to={`/catalog/${id}`}>
                                    <Button className='me-0 mb-5 mb-md-0 me-md-5' variant={'secondary'} type={'submit'}
                                            style={{minWidth: "175px"}}>
                                        Cancel
                                    </Button>
                                </Link>
                                <Button variant={'primary '} type={'submit'} style={{minWidth: "175px"}}>
                                    Create Items
                                </Button>
                            </div>
                        </form>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const OnboardWrapper = () => {
    const intl = useIntl()

    const tenantData = TenantService.getTenantFromLocalStorage();

    return (
        <>
            <PageTitle breadcrumbs={[{
                title: 'Catalog',
                path: '/catalog',
                isActive: false
            }]}>{intl.formatMessage({id: 'MENU.ONBOARD'})}</PageTitle>
            <OnboardPage/>
            <Helmet>
                <title>
                    {intl.formatMessage({id: 'MENU.ONBOARD'})}
                    {tenantData?.title ? ` - ${tenantData.title}` : ''}
                </title>
            </Helmet>
        </>
    )
}


export default OnboardWrapper